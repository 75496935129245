<template>
	<!-- <NewViewId msg="Welcome to Your Vue.js App"/> -->
	<div class="content">
		<div class="content-left" v-show="quType == 1 && bingShow == 1">
			<div class="content-left-noe">
				<div class="conteent-imags">
					<img src="@/assets/gdp.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">全年实现生产总值</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.shengchanzongzhi | keyTofixed
            }}</span>
						<span style="font-size: 13px">亿元</span>
					</div>
				</div>
			</div>
			<div class="content-left-noe">
				<div class="conteent-imags">
					<img src="@/assets/caizheng.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">地方财政收入</div>
					<div class="content-text-botton">
						<span class="left_zation">{{ homeData.caizhengshouru | keyTofixed  }}</span>
						<span style="font-size: 13px">亿元</span>
					</div>
				</div>
			</div>
			<div class="content-left-noe">
				<div class="conteent-imags">
					<img src="@/assets/guishang.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">规上工业增加值</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.guishanggongyezhi | keyTofixed
            }}</span>
						<span style="font-size: 13px">亿元</span>
					</div>
				</div>
			</div>
			<div class="content-left-noe">
				<div class="conteent-imags">
					<img src="@/assets/renjun.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">城镇居民人均可支配收入</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.renjunzhipeishouru | keyTofixed
            }}</span>
						<span style="font-size: 13px">万元</span>
					</div>
				</div>
			</div>
			<div class="content-left-noe">
				<div class="conteent-imags">
					<img src="@/assets/renjun.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">农村居民人均可支配收入</div>
					<div class="content-text-botton">
						<span class="left_zation">{{ homeData.nongmin | keyTofixed }}</span>
						<span style="font-size: 13px">万元</span>
					</div>
				</div>
			</div>
			<div class="content-left-noe">
				<div class="conteent-imags">
					<img src="@/assets/zichan.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">固定资产投资</div>
					<div class="content-text-botton">
						<span style="font-size: 13px">同比增长</span>
						<span class="left_zation">{{
              homeData.gudingzichantouru | keyTofixed
            }}</span>
						<span style="font-size: 13px">%</span>
					</div>
				</div>
			</div>

			<!-- <div class="tongji">
				<div class="tongji-text">{{ year }}年三产增加值</div>
				<div id="main" style="width: 240x; height: 165px"></div>
			</div> -->
		</div>
		<div class="content-left" v-show="quType == 1 && bingShow == 2">
			<div class="content-left-two">
				<div class="conteent-imags">
					<img src="@/assets/gdp.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">全年实现生产总值</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.shengchanzongzhi | keyTofixed
            }}</span>
						<span style="font-size: 13px">亿元</span>
					</div>
				</div>
			</div>
			<div class="content-left-two">
				<div class="conteent-imags">
					<img src="@/assets/caizheng.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">地方财政收入</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.caizhengshouru | keyTofixed
            }}</span>
						<span style="font-size: 13px">亿元</span>
					</div>
				</div>
			</div>
			<div class="content-left-two">
				<div class="conteent-imags">
					<img src="@/assets/guishang.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">规上工业增加值</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.guishanggongyezhi | keyTofixed
            }}</span>
						<span style="font-size: 13px">亿元</span>
					</div>
				</div>
			</div>
			<div class="content-left-two">
				<div class="conteent-imags">
					<img src="@/assets/renjun.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">城镇居民人均可支配收入</div>
					<div class="content-text-botton">
						<span class="left_zation">{{
              homeData.renjunzhipeishouru | keyTofixed
            }}</span>
						<span style="font-size: 13px">万元</span>
					</div>
				</div>
			</div>
			<div class="content-left-two">
				<div class="conteent-imags">
					<img src="@/assets/renjun.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">农村居民人均可支配收入</div>
					<div class="content-text-botton">
						<span class="left_zation">{{ homeData.nongmin | keyTofixed }}</span>
						<span style="font-size: 13px">万元</span>
					</div>
				</div>
			</div>
			<div class="content-left-two">
				<div class="conteent-imags">
					<img src="@/assets/zichan.png" alt="" />
				</div>
				<div class="content-text">
					<div class="content-text-top">固定资产投资</div>
					<div class="content-text-botton">
						<span style="font-size: 13px">同比增长</span>
						<span class="left_zation">{{
              homeData.gudingzichantouru | keyTofixed
            }}</span>
						<span style="font-size: 13px">%</span>
					</div>
				</div>
			</div>
		</div>
		<div class="content-zhong" :class="quType == 1 ? 'content_one' : 'content_two'">
			<div class="content-zhong-top" :class="quType == 1 ? 'content_one' : 'content_two'">
				<div class="content-zhong-top-image">
					<img src="@/assets/zhinanzhen.png" alt="" />
				</div>
				<div class="zhong-top-right">
					<div class="zhong-top-right-one" :class="quType == 1 ? 'twoActive' : ''" @click="xingChanQu(1)">
						行政区域
					</div>
					<div class="zhong-top-right-two" :class="quType == 2 ? 'twoActive' : ''" @click="xingChanQu(2)">
						产业园区
					</div>
				</div>
			</div>
			<div id="map" class="xingMap_one" v-show="quType == 1 && bingShow == 1"></div>
			<div id="mapTwo" class="xingMap_two" v-show="quType == 1 && bingShow == 2"></div>
			<div id="chanMap" v-show="quType == 2" style="width:0;height:0 "></div>
			<div class="mapCon" v-show="quType == 2" >
        <!-- 地图热点 -->
        <div @click="clickBtn('汉中经济技术开发区')" class="bgCol hot1"></div>
        <div @click="clickBtn('略阳绿色循环经济产业园区')" class="bgCol hot2"></div>
        <div @click="clickBtn('宁强高新技术产业开发区')" class="bgCol hot3"></div>
        <div @click="clickBtn('留坝秦岭山珍物流园区')" class="bgCol hot4"></div>
        <div @click="clickBtn('勉县经济技术开发区')" class="bgCol hot5"></div>
        <div @click="clickBtn('南郑高新技术产业开发区')" class="bgCol hot6"></div>
        <div @click="clickBtn('兴汉新区')" class="bgCol hot7"></div>
        <div @click="clickBtn('汉中航空经济技术开发区')" class="bgCol hot8"></div>
        <div @click="clickBtn('	佛坪绿色循环产业园(在建)')" class="bgCol hot9"></div>
        <div @click="clickBtn('洋县绿色（有机）循环产业园区')" class="bgCol hot10"></div>
        <div @click="clickBtn('城固三合循环经济产业园')" class="bgCol hot11"></div>
        <div @click="clickBtn('西乡循环经济产业园')" class="bgCol hot12"></div>
        <div @click="clickBtn('镇巴高新技术产业开发区')" class="bgCol hot13"></div>
        <img src="../../assets/map.jpg" style="width:100% ;">
      </div>
			<!-- <div class="zhong-text" v-if="quType == 1 && bingShow == 1">教育科技</div> -->
			<div class="qiyeshuliang" v-if="quType == 1 && bingShow == 1">
				<div class="kejixing">
					<div class="tupian">
						{{ homeData.kejixingzhongxiaoqiye
            }}<span style="font-size: 15px">家</span>
					</div>
					<div class="lejixing-tetx">科技型中小企业</div>
				</div>
				<div class="kejixing" style="margin-left: 70px">
					<div class="tupian" style="margin-left: 0px">
						{{ homeData.gaoxinjishuqiye
            }}<span style="font-size: 15px">家</span>
					</div>
					<div class="lejixing-tetx">高新技术企业</div>
				</div>
				<div class="kejixing" style="margin-left: 70px">
					<div class="tupian" style="margin-left: 0px">
						{{ homeData.putonggaoxiao }}<span style="font-size: 15px">所</span>
					</div>
					<div class="lejixing-tetx">普通高等学校</div>
				</div>
				<div class="kejixing" style="margin-left: 70px">
					<div class="tupian" style="margin-left: 0px">
						{{ homeData.zhongdengzhiye }}<span style="font-size: 15px">所</span>
					</div>
					<div class="lejixing-tetx">中等职业学校</div>
				</div>
			</div>
		</div>

		<div class="content-reght">
			<div class="shi">
				<div class="chengshi video_box">
					<div class="jiaobiao">{{ homeData.name }}</div>
					<!--             v-if="!homeData.video" -->
					<video class="video_style" :src="img_url + homeData.video" :poster="img_url + homeData.video_image"
						controls></video>
					<!-- <video
            v-if="homeData.video"
            class="video_style"
            :src="img_url + homeData.video"
            controls
          ></video> -->
					<!-- <div class="beijing" v-else></div> -->
				</div>
				<div class="chengshi juli">
					<div class="jiaobiaoao">区域介绍</div>
					<div class="d_f j_c_s f_w dibiao" v-html="homeData.jieshao"></div>
				</div>
			</div>
			<!-- <div class="tongjizhu" v-if="quType == 1">
				<div class="jiaobiao-tongji">{{ yearFan }}年GDP</div>
				<div id="zhu" style="width: 410px; height: 120px; margin: 0 auto"></div>
			</div> -->

			<!-- <div class="d_f j_c_sb" v-if="quType == 1"> -->
			<div v-if="quType == 1">
				<div class="zhongdianxiangmu_left">
					<div class="top-title">
						<div class="top-title-jiao">重点项目</div>
						<div class="top-gengguo">
							<!-- 更多 -->
						</div>
					</div>
					<div class="zhongdianxiangmu-content">
						<div class="content-xiangmu" v-for="(item, index) in xiangMuArr" :key="index">
							<p @click="goDetail(item)">{{ item.title }}</p>
							<!-- <p>投资总额：{{ item.touzijine }}万</p>
              <p>建设地点：{{ item.jianshedidian }}</p> -->
						</div>
					</div>
				</div>

				<div class="mingxing_compay">
					<div class="top-title">
						<div class="top-title-jiao">明星企业</div>
						<!-- <div class="top-gengguo">所属县区</div> -->
					</div>
					<div class="mingqi_box">
						<div class="company_list" v-for="(item, index) in mingQiArr" :key="index">
							<p @click="goDetail(item)">{{ item.title }}</p>
							<!-- <p>{{ item.jianshedidian }}</p> -->
						</div>
					</div>
				</div>
			</div>
			<div class="zhongdianxiangmu" v-if="quType == 2" style="height:70px">
				<div class="top-title">
					<div class="top-title-jiao">标准化厂房面积</div>
					<div class="top-gengguo"></div>
				</div>
				<div class="zhong_box" style="font-size: 14px;color: #9ed1ff;">
					标准化厂房面积：{{ homeData.area ? homeData.area : 0}}平方米
				</div>
			</div>
			<div class="zhongdianxiangmu" v-if="quType == 2">
				<div class="top-title">
					<div class="top-title-jiao">重点项目</div>
					<div class="top-gengguo">
						<!-- 更多 -->
					</div>
				</div>
				<div class="teshu_box">
					<div class="company_list d_f j_c_sb a_i_c" v-for="(item, index) in xiangMuArr" :key="index">
						<p @click="goDetail(item)">{{ item.title }}</p>
						<p>{{ item.touzijine }}亿</p>
					</div>
				</div>
			</div>
			<div class="zhongdianxiangmu" v-if="quType == 2">
				<div class="top-title">
					<div class="top-title-jiao">重点企业</div>
					<div class="top-gengguo">所属县区</div>
				</div>
				<div class="zhong_box">
					<div class="company_list d_f j_c_sb a_i_c" v-for="(item, index) in zhongQiArr" :key="index">
						<p @click="goDetail(item)">{{ item.title }}</p>
						<p>{{ item.jianshedidian }}</p>
					</div>
				</div>
			</div>
			<!-- <div class="zhongdianxiangmu" v-if="quType == 2">
        <div class="top-title">
          <div class="top-title-jiao">优惠政策</div>
          <div class="top-gengguo">
          
          </div>
        </div>
        <div class="teshu_box">
          <div
            class="company_list d_f j_c_sb a_i_c"
            v-for="(item, index) in zhengCeArr"
            :key="index"
          >
            <p @click="goDetail(item)">{{ item.title }}</p>
            <p>{{ item.created_at }}</p>
          </div>
        </div>
      </div> -->
		</div>

		<popupDetail :popData="popData" :popShow="popShow" :acType="acType" @popbtn="detailShow"></popupDetail>
	</div>
</template>

<script>
	var mapData = [{
			value: [107.128825, 33.248468, 100], //汉中航空经济技术开发区
			name: "",
		},
		// {
		// 	value: [107.000551, 33.06355, 100], //滨江新区闪烁的点展示
		// 	name: "",
		// },
		{
			value: [107.1131, 33.112176, 100], //星汉新区
			name: "",
		},
		{
			value: [107.038163, 33.183051, 100],
			name: "汉台区",
		},
		{
			value: [106.959803, 32.887876, 200],
			name: "南郑区",
		},
		{
			value: [107.256757, 33.336091, 300],
			name: "城固县",
		},
		{
			value: [107.550622, 33.422831, 400],
			name: "洋县",
		},
		{
			value: [107.656719, 32.963563, 500],
			name: "西乡县",
		},
		{
			value: [106.771948, 33.152772, 600],
			name: "勉县",
		},
		{
			value: [106.092431, 32.997157, 700],
			name: "宁强县",
		},
		{
			value: [106.132608, 33.272921, 700],
			name: "略阳县",
		},
		{
			value: [107.930146, 32.294275, 700],
			name: "镇巴县",
		},
		{
			value: ["", "", 700],
			name: "留坝县",
		},
		{
			value: ["", "", 700],
			name: "佛坪县",
		},
	];
	let LableDataT = [{
			name: "",
			coords: [
				[107.256757, 33.336091],
				[107.256757, 33.836091],
			],
		},
		{
			name: "城固三合循环经济产业园",
			coords: [
				[107.256757, 33.836091],
				[107.656757, 33.836091],
			],
		},

		{
			name: "洋县绿色（有机）循环产业园区",
			coords: [
				[107.550622, 33.422831],
				[108.030022, 33.422831],
			],
		},

		{
			name: "",
			coords: [
				[107.656719, 32.963563],
				[107.656719, 32.848963],
			],
		},
		{
			name: "西乡循环经济产业园",
			coords: [
				[107.656719, 32.848963],
				[107.776719, 32.848963],
			],
		},
		// 略阳绿色循环经济产业园区
		{
			name: "",
			coords: [
				[106.132608, 33.272921],
				[106.132608, 33.472921],
			],
		},
		{
			name: "略阳绿色循环经济产业园区",
			coords: [
				[106.132608, 33.472921],
				[106.032608, 33.472921],
			],
		},
		{
			name: "镇巴高新技术产业开发区",
			coords: [
				[107.930146, 32.294275],
				[107.530146, 32.294275],
			],
		},
		{
			name: "南郑高新技术产业开发区",
			coords: [
				[106.959803, 32.887876],
				[106.959803, 32.627876],
			],
		},
		{
			name: "",
			coords: [
				[106.771948, 33.152772],
				[106.271948, 33.152772],
			],
		},
		{
			name: "勉县经济技术开发区",
			coords: [
				[106.271948, 33.152772],
				[106.271948, 32.382772],
			],
		},
		{
			name: "",
			coords: [
				[106.092431, 32.997157],
				[106.092431, 32.597157],
			],
		},
		{
			name: "宁强高新技术产业开发区",
			coords: [
				[106.092431, 32.597157],
				[106.039931, 32.597157],
			],
		},
	];
	let LableDataO = [{
			name: "",
			coords: [
				[107.128825, 33.248468],
				[107.128825, 34.138468],
			],
		},
		{
			name: "汉中航空经济技术开发区",
			coords: [
				[107.128825, 34.138468],
				[106.728825, 34.138468],
			],
		},
		{
			name: "",
			coords: [
				[107.038163, 33.183051],
				[107.038163, 33.803051],
			],
		},
		{
			name: "汉中经济技术开发区",
			coords: [
				[107.038163, 33.803051],
				[106.238163, 33.803051],
			],
		},
		// {
		// 	name: "",
		// 	coords: [
		// 		[107.000551, 33.06355],
		// 		[106.600551, 33.06355],
		// 	],
		// },滨江新区的横线展示
		// {
		// 	name: "滨江新区",
		// 	coords: [
		// 		[106.600551, 33.06355],
		// 		[106.600551, 32.96355],
		// 	],
		// },
		{
			name: "兴汉新区",
			coords: [
				[107.1131, 33.112176],
				[107.4131, 33.112176],
			],
		},
	];

	import popupDetail from "@/components/popupDetail.vue";
	var YEAR = new Date().getFullYear();
	import {
		homeIndex,
		homeGdp,
		homeProjects
	} from "../../api/http.js";
	import {
		imgUrl
	} from "../../api/config";
	var activeAreaName = "";
	export default {
		name: "generalization",
		data() {
			return {
				quType: 1,
				pageSize: 1,
				year: new Date(new Date().setFullYear(YEAR - 1)).getFullYear(),
				img_url: imgUrl,
				zengZhi: [{
						value: 55,
						name: "第一产业增加值 同比增长"
					},
					{
						value: 50,
						name: "第二产业增加值 同比增长"
					},
					{
						value: 45,
						name: "第三产业增加值"
					},
				],
				// zoom: 11,
				// center: [121.59996, 31.197646],
				// events: {
				//   click: (e) => {
				//     alert("map clicked");
				//   },
				// },
				homeData: {},
				yearFan: "",
				mingQiArr: [],
				xiangMuArr: [],
				zhengCeArr: [],
				gdpNian: [],
				gdpZhi: [],
				zhongQiArr: [],
				myChartZhu: null,
				myChart: null,
				myChartMap: null,
				myChartXingMap: null,
				myChartXingMapTwo: null,
				popShow: false,
				popData: {},
				acType: 1,
				bingShow: 1,
			};
		},
		components: {
			popupDetail,
		},
		filters: {
			keyTofixed(value) {
				return Number(value).toFixed(2);
			},
		},
		mounted() {
			this.getMap();
			this.getMapTwo();
			// this.getChanMap();

			this.getHomeIndex("汉中市");
			this.getHomeGdp("");
			this.getHomeProjects("");
			this.getHomeProjectsMingQi("");
			// this.getHomeProjectsZheng("");
			this.getHomeProjectsZhong("");
		},
		methods: {
			detailShow() {
				this.popShow = false;
			},
			// 查看详情
			goDetail(data) {
				//  console.log('查看详情',data);
				this.acType = 1;
				this.popShow = true;
				this.popData = data;
			},
			// 获取首页数据
			getHomeIndex(text) {
				let params = {
					area: text,
				};
				homeIndex(params).then((res) => {
					console.log("获取数据1", res);
					if (res.code == 0) {
						let data = res.result;
						let zeng = this.zengZhi;
						this.homeData = data;
						zeng[0].name = zeng[0].name + data.diyi + "%";
						zeng[1].name = zeng[1].name + data.dier + "%";
						zeng[2].name = zeng[2].name + data.disan + "%";
						this.zengZhi = zeng;

						// this.myEcharts(zeng);
					}
				});
			},
			// 获取GDP数据
			getHomeGdp(text) {
				let params = {
					area: text,
				};
				homeGdp(params).then((res) => {
					console.log("获取数据2", res);
					if (res.code == 0) {
						let data = res.result;
						this.yearFan = data[data.length - 1].year + "-" + data[0].year;
						let nianArr = [];
						let gdpArr = [];
						data.forEach((item) => {
							nianArr.push(item.year);
							gdpArr.push(item.gdp);
						});
						this.gdpNian = nianArr.reverse();
						this.gdpZhi = gdpArr.reverse();
						//  console.log('年份',nianArr);
						// console.log("gdp", gdpArr);
						// this.myEchartss(nianArr, gdpArr);
					}
				});
			},
			// 获取重点项目
			getHomeProjects(text) {
				let params = {
					area: text,
					page: this.pageSize,
					type: 1,
				};
				homeProjects(params).then((res) => {
					console.log("获取数据项目3", res);
					if (res.code == 0) {
						this.xiangMuArr = res.result;
					}
				});
			},
			// 获取优惠政策
			// getHomeProjectsZheng(text) {
			//   let params = {
			//     area: text,
			//     page: this.pageSize,
			//     type: 2,
			//   };
			//   homeProjects(params).then((res) => {
			//     console.log("获取数据政策", res);
			//     if (res.code == 0) {
			//       this.zhengCeArr = res.result;
			//     }
			//   });
			// },
			// 获取明星企业
			getHomeProjectsMingQi(text) {
				let params = {
					area: text,
					page: this.pageSize,
					type: 3,
				};
				homeProjects(params).then((res) => {
					//  console.log('获取数据名企3',res);
					if (res.code == 0) {
						this.mingQiArr = res.result;
					}
				});
			},
			// 获取重点企业
			getHomeProjectsZhong(text) {
				let params = {
					area: text,
					page: this.pageSize,
					type: 4,
				};
				homeProjects(params).then((res) => {
					console.log("获取数据名企4", res);
					if (res.code == 0) {
						this.zhongQiArr = res.result;
					}
				});
			},
			// 行政区域和产业园区
			xingChanQu(e) {
				this.quType = e;
				// this.zengZhi = [];
				this.getHomeProjects("");
				this.getHomeProjectsMingQi("");
				if (e == 1) {
					this.getMap();
					this.getHomeIndex("汉中市");
					this.getHomeGdp("");
				} else {
					// this.myChartZhu.dispose();
					// this.myChart.dispose();
					// this.myChartXingMap.dispose();
					// this.getHomeProjectsZheng("");
					this.getHomeProjectsZhong("");
				}
			},
			// 产业园区
			getChanMap() {
				this.myChartMap = this.$echarts.init(document.getElementById("chanMap"));
				let mapJson = require("../../utils/hanzhong.json");
				this.$echarts.registerMap("area", mapJson);
				var outdatac = []; //地图区域挂载数据
				var maxDatac = parseInt(mapData[0].value); //热力最大值
				var minDatac = parseInt(mapData[0].value); //热力最小值
				mapData.forEach(function(item, index) {
					var num = parseInt(item.value);
					num >= maxDatac && (maxDatac = num);
					num <= minDatac && (minDatac = num);
					outdatac.push({
						name: item.name,
						value: item.value[2],
						index: index,
					});
					console.log(outdatac);
				});
				var options = {
					tooltip: {
						show: true,
						formatter: function(params) {
							// return params.name + "<br>" + params.value;
							return params.name;
						},
					},

					// 地图的阴影底图
					geo: {
						map: "area",
						aspectScale: 1,
						layoutCenter: ["47%", "56%"], //地图位置
						layoutSize: "117%",
						// z: 0, 
						// geoIndex: 1,
						label: {
							show: false,
						},
					},
					series: [{
							type: "map",
							map: "area",
							aspectScale: 1,
							layoutCenter: ["47%", "56%"], //地图位置
							layoutSize: "117%",
							label: {
								normal: {
									show: true,
									fontFamily: "SourceHanSansCN",
									fontSize: "10",
									color: "#FEFEFE",
								},
								emphasis: {
									show: true,
									fontFamily: "SourceHanSansCN",
									fontSize: "10",
									color: "#FEFEFE",
								},
							},
							// tooltip:{} tooltip可结合formatter、div、css实现样式
							itemStyle: {
								normal: {
									// borderColor: 'rgba(0,0,0, 0.6)',
									borderColor: "rgba(48,127,255, 0.6)",
									borderWidth: 0.8,
									areaColor: {
										type: "linear-gradient",
										x: 0,
										y: 300,
										x2: 0,
										y2: 0,
										colorStops: [{
												offset: 0,
												// color: '#0a56BC', // 0% 处的颜色
												color: "#011E5A",
											},
											{
												offset: 1,
												// color: '#12A6F2',
												color: "#011E5A",
											},
										],
										global: true, // 缺省为 false
									},
								},
								emphasis: {
									shadowColor: "rgba(0, 0, 0, 0)",
									shadowBlur: 0,
									shadowOffsetX: 0,
									shadowOffsetY: 0,
									areaColor: {
										type: "linear-gradient",
										x: 0,
										y: 0,
										x2: 0,
										y2: 1,
										colorStops: [{
												offset: 0,
												// color: '#F5B615', // 0% 处的颜色
												color: "#011e5a",
											},
											{
												offset: 1,
												// color: '#E27B0d',
												color: "#011e5a",
											},
										],
									},
								},
							},
							selectedMode: true,
							select: {
								label: {
									color: "#ffffff",
								},
								itemStyle: {
									areaColor: "#011e5a",
								},
							},
							zlevel: 1,
							data: outdatac,
						},
						//散点
						{
							type: "effectScatter",
							coordinateSystem: "geo",
							rippleEffect: {
								brushType: "stroke",
							},
							showEffectOn: "render",
							itemStyle: {
								normal: {
									color: {
										type: "radial",
										x: 0.5,
										y: 0.5,
										r: 0.5,
										colorStops: [{
												offset: 0,
												color: "rgba(0,228,242,0.5)",
											},
											{
												offset: 0.8,
												color: "rgba(0,228,242,0.8)",
											},
											{
												offset: 1,
												color: "rgba(0,228,242,1)",
											},
										],
										global: false, // 缺省为 false
									},
								},
							},
							// label: {
							//     normal: {
							//         show: true,
							//         color: '#fff',
							//         fontWeight: 'bold',
							//         position: [10, 10],
							//         formatter: function (para) {
							//             //return '{cnNum|' + para.data.value[2] + '}'
							//             return '{cnNum|' + para.name + '}';
							//             //return ''
							//         },
							//         rich: {
							//             cnNum: {
							//                 fontSize: 14,
							//                 color: '#FEFEFE',
							//             },
							//         },
							//     },
							// },
							symbol: "circle",
							symbolSize: function(val) {
								// return (val[2] / maxData) * 4;
								return 10;
							},
							data: mapData,
							zlevel: 2,
						},
						// 连线框1
						{
							type: "lines",
							zlevel: 5,
							symbol: "circle",
							symbolSize: 5,
							color: "#38FFFF",

							// opacity: 1,
							label: {
								show: true,
								padding: [0, 10],
								color: "#38FFFF",
								borderWidth: 2,
								borderRadius: 6,
								formatter(params) {
									let arr = [params.name];
									//文本提示框
									if (
										params.name == "城固三合循环经济产业园" ||
										params.name == "洋县绿色（有机）循环产业园区" ||
										params.name == "西乡循环经济产业园"
									) {
										if (params.name == "城固三合循环经济产业园") {
											return (
												"{borderLeft|" +
												"" +
												"}" +
												"{title|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"绿色食药" +
												"}" +
												"\n{text_style|" +
												"硅材料" +
												"}" +
												"\n{text_style|" +
												"石墨材料" +
												"}"
											);
										} else if (params.name == "洋县绿色（有机）循环产业园区") {
											return (
												"{borderLeft|" +
												"" +
												"}" +
												"{title|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"钒|钛为主" +
												"}" +
												"\n{text_style|" +
												"有色金属材料" +
												"}"
											);
										} else if (params.name == "西乡循环经济产业园") {
											return (
												"{borderLeft|" +
												"" +
												"}" +
												"{title|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"绿色食品深加工" +
												"}" +
												"\n{text_style|" +
												"纺织产业" +
												"}"
											);
										}
									} else if (
										params.name == "镇巴高新技术产业开发区" ||
										params.name == "宁强高新技术产业开发区" ||
										params.name == "略阳绿色循环经济产业园区"
									) {
										if (params.name == "镇巴高新技术产业开发区") {
											return (
												"{title|" +
												params.name +
												"}" +
												"{borderLeft|" +
												"" +
												"}" +
												"\n{text_style|" +
												"农副产品加工产业" +
												"}"
											);
										} else if (params.name == "宁强高新技术产业开发区") {
											return (
												"{title|" +
												params.name +
												"}" +
												"{borderLeft|" +
												"" +
												"}" +
												"\n{text_style|" +
												"农副产品加工" +
												"}"
											);
										} else if (params.name == "略阳绿色循环经济产业园区") {
											return (
												"{title|" +
												params.name +
												"}" +
												"{borderLeft|" +
												"" +
												"}" +
												"\n{text_style|" +
												"功能食品" +
												"}" +
												"\n{text_style|" +
												"保健品产业" +
												"}"
											);
										}
									} else if (
										params.name == "南郑高新技术产业开发区" ||
										params.name == "勉县经济技术开发区" ||
										params.name == "略阳绿色循环经济产业园区"
									) {
										if (params.name == "南郑高新技术产业开发区") {
											return (
												"{borderW|" +
												"" +
												"}" +
												"\n{titleW|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"装配式建筑" +
												"}"
											);
										} else if (params.name == "勉县经济技术开发区") {
											return (
												"{borderW|" +
												"" +
												"}" +
												"\n{titleW|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"钢铁冶炼" +
												"}" +
												"\n{text_style|" +
												"精深加工" +
												"}" +
												"\n{text_style|" +
												"锌材料等金属材料产业" +
												"}"
											);
										} else if (params.name == "略阳绿色循环经济产业园区") {
											return (
												"{borderW|" +
												"" +
												"}" +
												"\n{titleW|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"功能食品" +
												"}" +
												"\n{text_style|" +
												"保健品产业" +
												"}"
											);
										}
									}
								},
								rich: {
									text_style: {
										align: "center",
										fontSize: "13px",
										color: "#fff",
										padding: [5, 0, 0, 0],
									},
									borderLeft: {
										width: 5,
										height: 30,
										backgroundColor: "#307FFF",
									},
									title: {
										lineHeight: 30,
										fontSize: "14px",
										color: "#fff",
										backgroundColor: "#0051FF",
										height: 30,
										padding: [0, 15, 0, 15],
									},
									borderW: {
										width: 170,
										height: 5,
										backgroundColor: "#307FFF",
									},
									titleW: {
										width: 170,
										lineHeight: 30,
										fontSize: "14px",
										color: "#fff",
										backgroundColor: "#0051FF",
										height: 30,
									},
								},
								// textStyle: {
								//     align: 'left',
								//     lineHeight: 20,
								//     color: '#9ED1FF',
								//     fontSize: '18px'
								// },
							},
							lineStyle: {
								type: "solid",
								color: "#38FFFF",
								width: 2,
							},
							// 高亮样式。
							emphasis: {
								label: {
									show: true,
									padding: [0, 10],
									// backgroundColor: "#ff0000",
									formatter(params) {
										let arr = [params.name];
										//文本提示框
										if (
											params.name == "城固三合循环经济产业园" ||
											params.name == "洋县绿色（有机）循环产业园区" ||
											params.name == "西乡循环经济产业园"
										) {
											if (params.name == "城固三合循环经济产业园") {
												return (
													"{borderLeft|" +
													"" +
													"}" +
													"{title|" +
													params.name +
													"}" +
													"\n{text_style|" +
													"绿色食药" +
													"}" +
													"\n{text_style|" +
													"硅材料" +
													"}" +
													"\n{text_style|" +
													"石墨材料" +
													"}"
												);
											} else if (params.name == "洋县绿色（有机）循环产业园区") {
												return (
													"{borderLeft|" +
													"" +
													"}" +
													"{title|" +
													params.name +
													"}" +
													"\n{text_style|" +
													"钒|钛为主" +
													"}" +
													"\n{text_style|" +
													"有色金属材料" +
													"}"
												);
											} else if (params.name == "西乡循环经济产业园") {
												return (
													"{borderLeft|" +
													"" +
													"}" +
													"{title|" +
													params.name +
													"}" +
													"\n{text_style|" +
													"绿色食品深加工" +
													"}" +
													"\n{text_style|" +
													"纺织产业" +
													"}"
												);
											}
										} else if (
											params.name == "镇巴高新技术产业开发区" ||
											params.name == "宁强高新技术产业开发区" ||
											params.name == "略阳绿色循环经济产业园区"
										) {
											if (params.name == "镇巴高新技术产业开发区") {
												return (
													"{title|" +
													params.name +
													"}" +
													"{borderLeft|" +
													"" +
													"}" +
													"\n{text_style|" +
													"农副产品加工产业" +
													"}"
												);
											} else if (params.name == "宁强高新技术产业开发区") {
												return (
													"{title|" +
													params.name +
													"}" +
													"{borderLeft|" +
													"" +
													"}" +
													"\n{text_style|" +
													"农副产品加工" +
													"}"
												);
											} else if (params.name == "略阳绿色循环经济产业园区") {
												return (
													"{title|" +
													params.name +
													"}" +
													"{borderLeft|" +
													"" +
													"}" +
													"\n{text_style|" +
													"功能食品" +
													"}" +
													"\n{text_style|" +
													"保健品产业" +
													"}"
												);
											}
										} else if (
											params.name == "南郑高新技术产业开发区" ||
											params.name == "勉县经济技术开发区" ||
											params.name == "略阳绿色循环经济产业园区"
										) {
											if (params.name == "南郑高新技术产业开发区") {
												return (
													"{borderW|" +
													"" +
													"}" +
													"\n{titleW|" +
													params.name +
													"}" +
													"\n{text_style|" +
													"装配式建筑" +
													"}"
												);
											} else if (params.name == "勉县经济技术开发区") {
												return (
													"{borderW|" +
													"" +
													"}" +
													"\n{titleW|" +
													params.name +
													"}" +
													"\n{text_style|" +
													"钢铁冶炼" +
													"}" +
													"\n{text_style|" +
													"精深加工" +
													"}" +
													"\n{text_style|" +
													"锌材料等金属材料产业" +
													"}"
												);
											} else if (params.name == "略阳绿色循环经济产业园区") {
												return (
													"{borderW|" +
													"" +
													"}" +
													"\n{titleW|" +
													params.name +
													"}" +
													"\n{text_style|" +
													"功能食品" +
													"}" +
													"\n{text_style|" +
													"保健品产业" +
													"}"
												);
											}
										}
									},
									rich: {
										text_style: {
											align: "center",
											fontSize: "13px",
											color: "#fff",
											padding: [5, 0, 0, 0],
										},
										borderLeft: {
											width: 5,
											height: 30,
											backgroundColor: "#00ffff",
										},
										title: {
											lineHeight: 30,
											fontSize: "14px",
											color: "#fff",
											backgroundColor: "#5500ff",
											height: 30,
											padding: [0, 15, 0, 15],
										},
										borderW: {
											width: 170,
											height: 5,
											backgroundColor: "#00ffff",
										},
										titleW: {
											width: 170,
											lineHeight: 30,
											fontSize: "14px",
											color: "#fff",
											backgroundColor: "#5500ff",
											height: 30,
										},
									},
									borderWidth: 2,
									borderRadius: 6,
									// 高亮时标签的文字
									// formatter: '高亮时显示的标签内容'
								}
							},
							data: LableDataT,
						},
						// 连线框2
						{
							type: "lines",
							zlevel: 8,
							symbol: "circle",
							symbolSize: [5, 5],
							color: "#38FFFF",
							// opacity: 1,
							label: {
								show: true,
								padding: [0, 10],
								color: "#38FFFF",
								borderWidth: 2,
								borderRadius: 6,
								formatter(params) {
									let arr = [params.name];
									//文本提示框
									if (params.name == "兴汉新区") {
										return (
											"{borderLeft|" +
											"" +
											"}" +
											"{titleT|" +
											params.name +
											"}" +
											"\n{text_style|" +
											"文化旅游" +
											"}" +
											"\n{text_style|" +
											"生态康养" +
											"}"
										);
									} else if (
										params.name == "汉中航空经济技术开发区" ||
										params.name == "汉中经济技术开发区"
									) {
										if (params.name == "汉中航空经济技术开发区") {
											return (
												"{titleT|" +
												params.name +
												"}" +
												"{borderLeft|" +
												"" +
												"}" +
												"\n{text_style|" +
												"高端装备制作" +
												"}" +
												"\n{text_style|" +
												"航空装备制作" +
												"}" +
												"\n{text_style|" +
												"航空运营服务" +
												"}"
											);
										} else if (params.name == "汉中经济技术开发区") {
											return (
												"{titleT|" +
												params.name +
												"}" +
												"{borderLeft|" +
												"" +
												"}" +
												"\n{text_style|" +
												"生物医药" +
												"}" +
												"\n{text_style|" +
												"智能装备制造" +
												"}"
											);
										}
									} 
                  // else if (params.name == "滨江新区") {
									// 	return (
									// 		"{borderWidth|" +
									// 		"" +
									// 		"}" +
									// 		"\n{titleW|" +
									// 		params.name +
									// 		"}" +
									// 		"\n{text_style|" +
									// 		"现代金融" +
									// 		"}" +
									// 		"\n{text_style|" +
									// 		"现代商贸" +
									// 		"}"
									// 	);
									// }
								},
								rich: {
									text_style: {
										align: "center",
										fontSize: "13px",
										color: "#fff",
										padding: [5, 0, 0, 0],
									},
									borderLeft: {
										width: 5,
										height: 30,
										backgroundColor: "#307FFF",
									},
									borderWidth: {
										width: 50,
										padding: [0, 20, 0, 15],
										height: 5,
										backgroundColor: "#307FFF",
									},

									titleT: {
										lineHeight: 30,
										fontSize: "14px",
										color: "#FEFFFF",
										backgroundColor: "#0051FF",
										height: 30,
										padding: [0, 20, 0, 15],
									},
									titleW: {
										width: 50,
										lineHeight: 30,
										fontSize: "14px",
										color: "#FEFFFF",
										backgroundColor: "#0051FF",
										height: 30,
										align: "center",
										padding: [0, 20, 0, 15],
									},
								},
								// textStyle: {
								//     align: 'right',
								//     lineHeight: 20,
								//     color: '#9ED1FF',
								//     fontSize: '18px'
								// },
							},

							lineStyle: {
								type: "solid",
								color: "#38FFFF",
								width: 2,
							},
							// 高亮样式。
							emphasis: {
								label: {
									show: true,
									padding: [0, 10],
									// backgroundColor: "#ff0000",
									formatter(params) {
										let arr = [params.name];
										//文本提示框
										if (params.name == "兴汉新区") {
											return (
												"{borderLeft|" +
												"" +
												"}" +
												"{titleT|" +
												params.name +
												"}" +
												"\n{text_style|" +
												"文化旅游" +
												"}" +
												"\n{text_style|" +
												"生态康养" +
												"}"
											);
										} else if (
											params.name == "汉中航空经济技术开发区" ||
											params.name == "汉中经济技术开发区"
										) {
											if (params.name == "汉中航空经济技术开发区") {
												return (
													"{titleT|" +
													params.name +
													"}" +
													"{borderLeft|" +
													"" +
													"}" +
													"\n{text_style|" +
													"高端装备制作" +
													"}" +
													"\n{text_style|" +
													"航空装备制作" +
													"}" +
													"\n{text_style|" +
													"航空运营服务" +
													"}"
												);
											} else if (params.name == "汉中经济技术开发区") {
												return (
													"{titleT|" +
													params.name +
													"}" +
													"{borderLeft|" +
													"" +
													"}" +
													"\n{text_style|" +
													"生物医药" +
													"}" +
													"\n{text_style|" +
													"智能装备制造" +
													"}"
												);
											}
										} 
                    // else if (params.name == "滨江新区") {
										// 	return (
										// 		"{borderWidth|" +
										// 		"" +
										// 		"}" +
										// 		"\n{titleW|" +
										// 		params.name +
										// 		"}" +
										// 		"\n{text_style|" +
										// 		"现代金融" +
										// 		"}" +
										// 		"\n{text_style|" +
										// 		"现代商贸" +
										// 		"}"
										// 	);
										// }
									},
									rich: {
										text_style: {
											align: "center",
											fontSize: "13px",
											color: "#fff",
											padding: [5, 0, 0, 0],
										},
										borderLeft: {
											width: 5,
											height: 30,
											backgroundColor: "#00ffff",
										},
										borderWidth: {
											width: 50,
											padding: [0, 20, 0, 15],
											height: 5,
											backgroundColor: "#00ffff",
										},

										titleT: {
											lineHeight: 30,
											fontSize: "14px",
											color: "#FEFFFF",
											backgroundColor: "#5500ff",
											height: 30,
											padding: [0, 20, 0, 15],
										},
										titleW: {
											width: 50,
											lineHeight: 30,
											fontSize: "14px",
											color: "#FEFFFF",
											backgroundColor: "#5500ff",
											height: 30,
											align: "center",
											padding: [0, 20, 0, 15],
										},
									},
									borderWidth: 2,
									borderRadius: 6,
									// 高亮时标签的文字
									// formatter: '高亮时显示的标签内容'
								}
							},
							data: LableDataO
						},
					],
				};
				this.myChartMap.on("click", ({
					name
				}) => {
					console.log("点击弹框", name);
					if (activeAreaName !== name) {
						activeAreaName = name;
						if (
							name == "汉台区" ||
							name == "南郑区" ||
							name == "城固县" ||
							name == "洋县" ||
							name == "西乡县" ||
							name == "勉县" ||
							name == "宁强县" ||
							name == "略阳县" ||
							name == "镇巴县" ||
							name == "留坝县" ||
							name == "佛坪县"
						) {
							return false;
						} else {
							if (name == "南郑高新技术产业开发区") {
								name = "南郑高新技术产业开发区"
							}
							this.getChanMap();
							this.getHomeIndex(name);
							this.getHomeProjects(name);
							this.getHomeProjectsMingQi(name);
							// this.getHomeProjectsZheng(name);
							this.getHomeProjectsZhong(name);
						}
					}
				});
				// 点击某块区域
				//  this.myChartMap.on("click", (e) => {
				//   console.log('点击弹框',e);
				//   this.zengZhi = [];

				//   // this.getHomeIndex(e.name);
				//   // this.getHomeProjects(e.name);
				//   // this.getHomeProjectsMingQi(e.name);
				//   // if (this.quType == 1) {
				//   //   this.getHomeGdp(e.name);
				//   // } else {
				//   //   this.getHomeProjectsZheng("");
				//   // }
				// });
				this.myChartMap.setOption(options);

				window.addEventListener("resize", () => {
					this.myChartMap.resize();
				});
			},
			// 行政区域2
			getMapTwo(name) {
				this.myChartXingMapTwo = this.$echarts.init(
					document.getElementById("mapTwo")
				);
				let mapJson = require("../../utils/hanzhong.json");
				this.$echarts.registerMap("area", mapJson);
				var outdata = []; //地图区域挂载数据
				var maxData = parseInt(mapData[0].value); //热力最大值
				var minData = parseInt(mapData[0].value); //热力最小值
				mapData.forEach(function(item, index) {
					var num = parseInt(item.value);
					num >= maxData && (maxData = num);
					num <= minData && (minData = num);
					outdata.push({
						name: item.name,
						value: item.value[2],
						index: index,
					});
					// console.log(outdata);
				});
				var optionMap = {
					tooltip: {
						show: true,
						formatter: function(params) {
							return params.name;
						},
					},
					grid: {
						left: "1%",
						right: "1%",
						top: "1%",
						bottom: "1%",
						show: true,
						borderColor: "#011E5A",
						// backgroundColor:'#000'
					},

					series: [{
						type: "map",
						map: "area",
						regions: [{
							name: "宁强县", //与china.js对应的省份名称
							itemStyle: {
								areaColor: "#2DB088", //省份背景色
								borderColor: "#2DB088",
							},
						}, ],
						aspectScale: 0.9,
						layoutCenter: ["50%", "50%"], //地图位置
						layoutSize: "140%",
						label: {
							normal: {
								show: true,
								fontFamily: "SourceHanSansCN",
								fontSize: "14",
								color: "#FEFEFE",
							},
							emphasis: {
								show: true,
								fontFamily: "SourceHanSansCN",
								fontSize: "14",
								color: "#FEFEFE",
							},
						},
						// tooltip:{} tooltip可结合formatter、div、css实现样式
						itemStyle: {
							normal: {
								// borderColor: 'rgba(0,0,0, 0.6)',
								borderColor: "#307FFF",
								borderWidth: 2,
								areaColor: {
									type: "linear-gradient",
									x: 0,
									y: 300,
									x2: 0,
									y2: 0,
									colorStops: [{
											offset: 0,
											// color: '#0a56BC', // 0% 处的颜色
											color: "#011E5A",
										},
										{
											offset: 1,
											// color: '#12A6F2',
											color: "#011E5A",
										},
									],
									global: true, // 缺省为 false
								},
							},
							emphasis: {
								shadowColor: "rgba(0, 0, 0, 1)",
								shadowBlur: 10,
								shadowOffsetX: 5,
								shadowOffsetY: 5,
								areaColor: {
									type: "linear-gradient",
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											// color: '#F5B615', // 0% 处的颜色
											color: "#2CD03E",
										},
										{
											offset: 1,
											// color: '#E27B0d',
											color: "#2CD03E",
										},
									],
								},
							},
						},
						selectedMode: true,
						select: {
							label: {
								color: "#ffffff",
							},
							itemStyle: {
								areaColor: "#2CD03E",
							},
						},
						zlevel: 1,
						data: outdata,
					}, ],
				};

				// 点击某块区域
				this.myChartXingMapTwo.on("click", (e) => {
					console.log("点击地图", e);
					this.zengZhi = [];

					this.getHomeIndex(e.name);
					this.getHomeProjects(e.name);
					this.getHomeProjectsMingQi(e.name);
					// if (this.quType == 1) {
					this.getHomeGdp(e.name);
					// } else {
					//   this.getHomeProjectsZheng("");
					// }
				});

				this.myChartXingMapTwo.setOption(optionMap);

				window.addEventListener("resize", function() {
					this.myChartXingMapTwo.resize();
				});
			},
			// 行政区域
			getMap() {
				this.myChartXingMap = this.$echarts.init(document.getElementById("map"));
				let mapJson = require("../../utils/hanzhong.json");
				this.$echarts.registerMap("area", mapJson);
				var outdata = []; //地图区域挂载数据
				var maxData = parseInt(mapData[0].value); //热力最大值
				var minData = parseInt(mapData[0].value); //热力最小值
				mapData.forEach(function(item, index) {
					var num = parseInt(item.value);
					num >= maxData && (maxData = num);
					num <= minData && (minData = num);
					outdata.push({
						name: item.name,
						value: item.value[2],
						index: index,
					});
					// console.log(outdata);
				});
				var optionMap = {
					tooltip: {
						show: true,
						formatter: function(params) {
							return params.name;
						},
					},
					grid: {
						left: "1%",
						right: "1%",
						top: "1%",
						bottom: "1%",
						show: true,
						borderColor: "#011E5A",
						// backgroundColor:'#000'
					},

					series: [{
						type: "map",
						map: "area",
						aspectScale: 0.9,
						layoutCenter: ["50%", "50%"], //地图位置
						layoutSize: "144%",
						label: {
							normal: {
								show: true,
								fontFamily: "SourceHanSansCN",
								fontSize: "14",
								color: "#FEFEFE",
							},
							emphasis: {
								show: true,
								fontFamily: "SourceHanSansCN",
								fontSize: "14",
								color: "#FEFEFE",
							},
						},
						// tooltip:{} tooltip可结合formatter、div、css实现样式
						itemStyle: {
							normal: {
								// borderColor: 'rgba(0,0,0, 0.6)',
								borderColor: "#307FFF",
								borderWidth: 2,
								areaColor: {
									type: "linear-gradient",
									x: 0,
									y: 300,
									x2: 0,
									y2: 0,
									colorStops: [{
											offset: 0,
											// color: '#0a56BC', // 0% 处的颜色
											color: "#011E5A",
										},
										{
											offset: 1,
											// color: '#12A6F2',
											color: "#011E5A",
										},
									],
									global: true, // 缺省为 false
								},
							},
							emphasis: {
								show: false,
								shadowColor: "rgba(0, 0, 0, 1)",
								shadowBlur: 10,
								shadowOffsetX: 5,
								shadowOffsetY: 5,
								areaColor: {
									type: "linear-gradient",
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											// color: '#F5B615', // 0% 处的颜色
											color: "#2CD03E",
										},
										{
											offset: 1,
											// color: '#E27B0d',
											color: "#2CD03E",
										},
									],
								},
							},
						},
						selectedMode: true,
						select: {
							label: {
								color: "#ffffff",
							},
							itemStyle: {
								areaColor: "#2CD03E",
							},
						},
						zlevel: 1,
						data: outdata,
					}, ],
				};

				// 点击某块区域
				this.myChartXingMap.on("click", (e) => {
					console.log("点击地图", e);
					// this.zengZhi = [];
					this.bingShow = 2;
					this.getMapTwo(e.name);

					this.getHomeIndex(e.name);
					this.getHomeProjects(e.name);
					this.getHomeProjectsMingQi(e.name);
					// if (this.quType == 1) {
					this.getHomeGdp(e.name);
					// } else {
					//   this.getHomeProjectsZheng("");
					// }
				});

				this.myChartXingMap.setOption(optionMap);

				window.addEventListener("resize", function() {
					this.myChartXingMap.resize();
				});
			},
			//饼状图
			myEcharts(zeng) {
				this.myChart = this.$echarts.init(document.getElementById("main"));
				//配置图表
				var option = {
					color: ["#80BD26", "#F69F25", "#2D9CDD"],
					series: [{
						name: "Nightingale Chart",
						type: "pie",
						top: "5%",
						left: "-15%",
						radius: "95%",

						label: {
							show: true,
							color: "#ffffff",
							position: "inside",
							fontSize: 10,
							lineHeight: 12,
							width: "50",
							overflow: "breakAll",
						},

						center: ["50%", "50%"],
						roseType: "area",
						itemStyle: {
							// borderRadius: 5,
						},
						data: zeng, // this.zengZhi,
					}, ],
				};
				this.myChart.setOption(option);
				window.addEventListener("resize", () => {
					this.myChart.resize();
				});
			},

			//柱状图
			myEchartss() {
				this.myChartZhu = this.$echarts.init(document.getElementById("zhu"));

				var options = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					grid: {
						top: "10%",
						left: "3%",
						right: "4%",
						bottom: "5%",
						containLabel: true,
					},
					xAxis: [{
						type: "category",
						// boundaryGap: false,
						data: this.gdpNian,
						axisLine: {
							lineStyle: {
								color: "#9ED1FF",
								type: "dashed",
								width: 1,
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: "#9ED1FF",
								type: "dashed",
								width: 1,
							},
						},
					}, ],
					yAxis: [{
						type: "value",
						axisLine: {
							lineStyle: {
								width: 1,
								color: "#9ED1FF",
								type: "dashed",
								dashOffset: [4, 16],
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							lineStyle: {
								color: "#9ED1FF",
								width: 1,
								type: "dashed",
								dashOffset: [4, 16],
							},
						},
					}, ],
					series: [{
						name: "GDP",
						type: "bar",
						itemStyle: {
							normal: {
								color: {
									type: "linear",
									x: 0,
									y: 1,
									x2: 0,
									y2: 0,
									colorStops: [{
											offset: 0,
											color: "#011E5A", // 0% 处的颜色
										},
										{
											offset: 1,
											color: "#307FFF", // 100% 处的颜色
										},
									],
									global: false, // 缺省为 false
								},

								// new this.$echarts.graphic.LinearGradient(
								//   0,
								//   1,
								//   0,
								//   0,
								//   [
								//     {
								//       offset: 0,
								//       color: "#011E5A", // 0% 处的颜色
								//     },
								//     {
								//       offset: 1,
								//       color: "#307FFF", // 100% 处的颜色
								//     },
								//   ],
								//   false
								// ),
							},
						},
						barWidth: 20,
						data: this.gdpZhi,
						animation: true,
					}, ],
				};
				this.myChartZhu.setOption(options);
				window.addEventListener("resize", () => {
					this.myChartZhu.resize();
				});
			},
		
      // 切换右边视图
      clickBtn(name){
        // this.getChanMap();
        this.getHomeIndex(name);
        this.getHomeProjects(name);
        this.getHomeProjectsMingQi(name);
        // this.getHomeProjectsZheng(name);
        this.getHomeProjectsZhong(name);
      }
    },
	};
</script>
<style>
	html,
	body {
		background-color: #011e5a;
	}

	.company_list {
		margin-bottom: 10px;
	}

	.company_list>p:first-child {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}

	.company_list>p {
		font-size: 14px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #9ed1ff;
	}

	.company_list:hover>p:first-child {
		color: #307fff;
	}

	.mingxing_compay {
		width: 410px;
		height: 110px;
		border: 2px solid #307fff;
		margin-top: 10px;
		box-sizing: border-box;
	}

	.zhongdianxiangmu_left {
		margin-top: 10px;
		width: 410px;
		height: 110px;
		border: 2px solid #307fff;
		box-sizing: border-box;
	}

	.content-xiangmu {
		margin-bottom: 10px;
		cursor: pointer;
	}

	.content-xiangmu>p {
		font-size: 14px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #9ed1ff;
	}

	.content-xiangmu>p:nth-child(1) {
		/* width: 180px; */
		/* margin-right: 20px; */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.content-xiangmu>p:nth-child(2) {
		width: 152px;
		margin-right: 42px;
	}

	.content-xiangmu:hover>p {
		color: #307fff;
	}

	.zhongdianxiangmu-content {
		/* width: 580px;
  margin: 10px auto; */
		height: 68px;
		margin-top: 5px;
		padding: 5px 6px 10px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: scroll;
		white-space: nowrap;
	}

	.teshu_box {
		height: 58px;
		padding: 5px 6px 0px;
		margin-top: 5px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: scroll;
		white-space: nowrap;
	}

	.mingqi_box {
		height: 68px;
		padding: 5px 6px 10px;
		margin-top: 5px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: scroll;
		white-space: nowrap;
	}

	.zhong_box {
		height: 60px;
		padding: 5px 6px 0px;
		margin-top: 5px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: scroll;
		white-space: nowrap;
	}

	.top-gengguo {
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 300;
		color: #9ed1ff;
	}

	.top-title-jiao {
		/* width: 130px; */
		padding: 0 30px 0 10px;
		height: 30px;
		background-color: rgba(48, 127, 255, 0.3);
		border-radius: 0px 0px 34px 0px;
		padding-left: 18px;
		box-sizing: border-box;
		line-height: 30px;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
	}

	.top-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 12px;
	}

	.zhongdianxiangmu {
		margin-top: 10px;
		width: 410px;
		height: 100px;
		border: 2px solid #307fff;
		box-sizing: border-box;
	}

	.jiaobiao-tongji {
		/* width: 187px; */
		display: inline-block;
		height: 30px;
		padding: 0 30px 0 10px;
		border-radius: 0px 0px 34px 0px;
		background-color: rgba(48, 127, 255, 0.3);
		padding-left: 16px;
		box-sizing: border-box;
		line-height: 30px;
		font-size: 18px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
	}

	.tongjizhu {
		margin-top: 10px;
		width: 410px;
		height: 150px;
		border: 2px solid #307fff;
		box-sizing: border-box;
	}

	.chanyedibiao-text {
		font-size: 24px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #9ed1ff;
		margin-left: 10px;
	}

	.dibiao {
		height: 96px;
		line-height: 1.5;
		overflow-x: hidden;
		overflow-y: scroll;
		margin-top: 5px;
		padding: 5px 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #ffffff;
		overflow-wrap: break-word;
	}

	/* .dibiao::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: rgba(48, 127, 255, 0.3);
	} */

	.dibiao::-webkit-scrollbar {
		display: block !important;
		width: 8px !important;
	}

	.dibiao::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(158, 209, 255, 0.4);
	}

	.xiaoyuandian {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #38ffff;
	}

	.chanyedibiao {
		margin-top: 20px;
		margin-left: 16px;
		display: flex;
		justify-content: left;
		align-items: center;
	}

	.video_style {
		width: 100%;
		height: 100%;
	}

	.video_box {
		position: relative;
		height: 180px;
	}

	.beijing {
		width: 100%;
		height: 100%;
		background-image: url("@/assets/hanzhong.jpg");
	}

	.juli {
		margin-top: 10px;
		height: 140px;
	}

	.jiaobiaoao {
		/* width: 100px; */
		height: 30px;
		background-color: rgba(48, 127, 255, 0.3);
		border-radius: 0px 0px 34px 0px;
		padding: 0 30px 0 18px;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 30px;
		display: inline-block;
	}

	.jiaobiao {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 20;
		/* width: 100px; */
		padding: 0 30px 0 18px;
		height: 30px;
		background-color: rgba(48, 127, 255, 0.3);
		border-radius: 0px 0px 34px 0px;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 30px;
	}

	.content-reght {
		margin: 15px 15px 0 0;
	}

	.chengshi {
		width: 410px;
		border: 2px solid #307fff;
		box-sizing: border-box;
	}

	.shi {
		width: 410px;
	}

	.lejixing-tetx {
		margin: 10px 0 0 0;
		font-size: 20px;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #9ed1ff;
	}

	.tupian {
		margin-left: 9px;
		width: 120px;
		height: 120px;
		background-image: url("@/assets/quan.png");
		text-align: center;
		line-height: 120px;
		color: #fff;
		font-size: 30px;
		font-family: SourceHanSansCN;
		font-weight: bold;
		color: #ffffff;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.qiyeshuliang {
		margin-top: 35px;
		width: 820px;
		display: flex;
		justify-content: space-between;
		padding: 0 30px;
		box-sizing: border-box;
	}

	html,
	body {
		margin: 0;
		padding: 0;
	}

	.content {
		width: 1536px;
		height: 745px;
		background-color: #011e5a;
		display: flex;
		justify-content: space-between;

		box-sizing: border-box;
	}

	.content-left {
		margin-top: 10px;
		margin-left: 20px;
	}

	.content-left-noe {
		width: 250px;
		height: 65px;
		border: 2px solid #307fff;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		margin-bottom: 10px;
	}

	.content-left-two {
		width: 250px;
		height: 90px;
		border: 2px solid #307fff;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		margin-bottom: 15px;
	}

	.content-left-two .conteent-imags {
		width: 45px;
		height: 45px;
		margin: 20px 0 0 10px;
		border-radius: 50%;
	}

	.content-left-two .conteent-imags img {
		width: 45px;
		height: 45px;
	}

	.content-left-noe .conteent-imags {
		width: 40px;
		height: 40px;
		margin: 10px 0 0 5px;
		border-radius: 50%;
	}

	.content-left-noe .conteent-imags img {
		width: 40px;
		height: 40px;
	}

	.content-text {
		/* margin: 0 0 0 2px; */
	}

	.content-left-noe .content-text-top {
		width: calc(250px - 70px);
		padding-right: 10px;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #9ed1ff;
		margin-top: 7px;
		padding-left: 5px;
		text-align: right;
	}

	.content-left-two .content-text-top {
		width: calc(250px - 72px);
		padding-right: 10px;
		font-size: 16px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #9ed1ff;
		margin-top: 20px;
		padding-left: 5px;
		text-align: right;
	}

	.content-left-noe .content-text-botton {
		text-align: right;
		font-size: 24px;
		font-family: UniDream;
		font-weight: normal;
		color: #ffffff;
		margin-top: 6px;
		padding-right: 15px;
	}

	.content-left-two .content-text-botton {
		text-align: right;
		font-size: 28px;
		font-family: UniDream;
		font-weight: normal;
		color: #ffffff;
		margin-top: 8px;
		padding-right: 15px;
	}

	.content-text-botton .left_zation {
		font-family: electronicFontDIGIT;
	}

	.tongji {
		width: 250px;
		margin: 15px 0 0 0;
	}

	.tongji-text {
		font-size: 20px;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #ffffff;
	}

	.content_one {
		width: 820px;
	}

	.content_two {
		width: 1080px;
	}

	.content-zhong {
		position: relative;
		margin-top: 12px;
	}

	.content-zhong-top {
		position: absolute;
		top: 0;
		height: 50px;
		display: flex;
		justify-content: space-between;
		z-index: 50;
	}

	.content-zhong-top-image {
		width: 35px;
		height: 50px;
		margin-left: 48px;
	}

	.content-zhong-top-image img {
		width: 35px;
		height: 50px;
	}

	.zhong-top-right {
		width: 370px;
		height: 50px;
		display: flex;
		justify-content: left;
		align-items: center;
		cursor: pointer;
	}

	.zhong-top-right>div {
		width: 181px;
		height: 40px;
		border: 2px solid #307fff;
		text-align: center;
		line-height: 40px;
		font-size: 22px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #307fff;
	}

	.zhong-top-right>div.twoActive {
		color: #ffffff;
		background-color: #307fff;
	}

	.xingMap_one {
		width: 820px;
		margin-top: 70px;
		height: 362px;
	}

	.xingMap_two {
		width: 820px;
		margin-top: 70px;
		height: 520px;
	}

	#chanMap {
		margin-top: 50px;
		width: 1080px;
		height: 580px;
	}

	.zhong-text {
		margin-top: 15px;
		margin-left: 2px;
		font-size: 20px;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #ffffff;
	}

  /* 地图 */
  .mapCon{
    position: relative;
    margin: 80px 0 0 20px;
  }
  .bgCol{position:absolute; width: 200px;height: 50px;cursor: pointer;}
  .hot1{
    left:8%;
    top:10%;
    width: 90px;
    height: 90px;
  }
  .hot2{
    left: 7%;
    top: 34%;
    width: 100px;
    height: 90px;
  }
  .hot3{
    left:9%;
    top:54%;
    width: 90px;
    height: 90px;
  }
   .hot4{
    left: 18%;
    top: 21%;
    width: 159px;
    height: 46px;
  }
  .hot5{
    left: 21%;
    top: 38%;
    width: 140px;
    height: 81px;
  }
  .hot6{
    left: 21%;
    top: 61%;
    width: 113px;
    height: 81px;
  }
  .hot7{
    left: 34%;
    top: 67%;
    width: 186px;
    height: 57px;
  }
  .hot8{
    left: 71%;
    top: 7%;
    width: 186px;
    height: 57px;
  }
  .hot9{
    left: 78%;
    top: 21%;
    width: 94px;
    height: 76px;
  }
  .hot10{
    left: 67%;
    top: 28%;
    width: 99px;
    height: 94px;
  }
  .hot11{
    left: 60%;
    top: 58%;
    width: 99px;
    height: 94px;
  }
  .hot12{
    left: 78%;
    top: 48%;
    width: 146px;
    height: 80px;
  }
  .hot13{
    left: 78%;
    top: 71%;
    width: 154px;
    height: 52px;
  }

</style>