<template>
	<div>
		<div class="login_head d_f j_c_sb a_i_c">
			<div class="web_box">
				<p class="web_title">汉中招商服务云平台</p>
				<p class="web_text">Hanzhong Investment Promotion Service Cloud Platform</p>
			</div>
			<div class="d_f j_c_e a_i_c head_btn">
				<!-- <p :class="idx == 1 ? 'active' : ''" @click="headBtn(1)">汉中概况</p>
        <p :class="idx == 2 ? 'active' : ''" @click="headBtn(2)">投资优势</p>
        <p :class="idx == 3 ? 'active' : ''" @click="headBtn(3)">产业布局</p>
        <p :class="idx == 4 ? 'active' : ''" @click="headBtn(4)">投资政策</p>
        <p :class="idx == 10 ? 'active' : ''" @click="headBtn(10)">资源要素</p>
        <p :class="idx == 5 ? 'active' : ''" @click="headBtn(5)">要素保障</p>
        <p :class="idx == 6 ? 'active' : ''" @click="headBtn(6)">我要投资</p>
        <p :class="idx == 7 ? 'active' : ''" @click="headBtn(7)">数据服务</p>
        <p :class="idx == 8 ? 'active' : ''" @click="headBtn(8)">招商工具</p>
        <p :class="idx == 9 ? 'active' : ''" @click="headBtn(9)">搜索</p> -->
				<router-link to="/generalization">汉中概况</router-link>
				<router-link to="/investmentAdvantages">投资优势</router-link>
				<router-link to="/investmentIndustry">产业布局</router-link>
				<router-link to="/policySupport">投资政策</router-link>
				<!-- <router-link to="/resources">资源要素</router-link> -->
				<router-link to="/elementGuarantee">要素保障</router-link>
				<router-link to="/toInvest">我要投资</router-link>
				<!-- <router-link to="/dataServe">数据服务</router-link> -->
				<p @click="headBtn(7)">数据服务</p>
				<p @click="headBtn(8)">招商工具</p>
				<p @click="headBtn(9)">搜索</p>
			</div>
		</div>
		<!-- 搜索弹框 -->
		<div class="public_popup" v-show="isShow" @click.stop="canclePop">
			<div class="public_content" :class="souType ? 'popup_top' : 'popup_top_bian'" @click.stop="stopPopBtn">
				<div class="sou_box d_f j_c_sb a_i_c">
					<input type="text" v-model="souText" placeholder="搜索优惠政策、重点项目、资源要素、政策服务"
						@input.stop="inputChange" />
					<div class="d_f j_c_e a_i_c">
						<div class="d_f j_c_c a_i_c delete_img" v-show="blurShow" @click.stop="deleteSou">
							<img src="../assets/delete.png" alt="" srcset="" />
						</div>
						<div class="d_f j_c_c a_i_c sou_img" @click="inputSou">
							<img src="../assets/sousuo.png" alt="" srcset="" />
						</div>
					</div>
				</div>
				<div class="content_list" v-show="!souType">
					<div class="web_btn d_f j_c_s a_i_c">
						<p :class="acType == 1 ? 'active' : ''" @click="webBtn(1)">
							优惠政策
						</p>
						<p :class="acType == 2 ? 'active' : ''" @click="webBtn(2)">
							重点项目
						</p>
						<p :class="acType == 3 ? 'active' : ''" @click="webBtn(3)">
							政策服务
						</p>
						<p :class="acType == 4 ? 'active' : ''" @click="webBtn(4)">
							资源要素
						</p>
					</div>
					<p class="sou_text">共找到{{ listArr.length }}条搜索内容</p>
					<div class="popup_list" v-if="listArr.length > 0">
						<div class="list_box" v-for="(item, index) in listArr" :key="index" @click="lookDetail(item)">
							<p class="pHover" v-if="acType == 1 || acType == 2">
								{{ item.title }}
							</p>
							<p class="pHover" v-if="acType == 3">{{ item.name }}</p>
							<p v-if="acType == 4">{{ item.name }}</p>
						</div>
					</div>
					<div class="popup_list" v-else>
						<p class="wuData">暂无数据</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 文本详情 -->
		<div class="support_popup" v-show="popShow" @click.stop="popShowBtn">
			<!-- @click.stop="popShowStop" -->
			<img src="../assets/xxx.png" alt="" class="popup_ximg" @click.stop="popShowBtn">
			<div class="popup_content">
				<p class="popup_title" v-if="acType == 1 || acType == 2">{{ popData.title }}</p>
				<p class="popup_title" v-if="acType == 3">{{ popData.name }}</p>
				<div class="d_f j_c_sb a_i_c popup_time">
					<p>{{ popData.updated_at }}</p>
					<p>{{ popData.author }}</p>
				</div>
				<div class="popup_text" v-html="popData.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		searchText
	} from "@/api/http";
	export default {
		name: "webHead",
		data() {
			return {
				idx: 1,
				isShow: false,
				blurShow: false,
				souType: true,
				souText: "",
				listArr: [],
				acType: 1,
				popShow: false,
				popData: {}
			};
		},
		props: {},
		methods: {
			// 文本详情弹框隐藏
			popShowBtn() {
				this.popShow = false;
			},
			// 查看详情
			lookDetail(data) {
				this.popData = data;
				this.popShow = true
			},
			// 弹框按钮切换
			webBtn(e) {
				this.acType = e;
				this.setSearch();
			},
			stopPopBtn() {},
			canclePop() {
				this.isShow = false;
				this.souType = true;
				this.blurShow = false;
				this.souText = "";
			},
			// 清空input
			deleteSou() {
				this.souText = "";
				this.souType = true
				// this.setSearch();
			},
			inputChange(e) {
				this.blurShow = true;
			},
			inputSou() {
				this.souType = false;
				this.setSearch();
			},
			// 搜索
			setSearch() {
				let params = {
					type: this.acType,
					text: this.souText,
					page: 1,
				};
				searchText(params).then((res) => {
					console.log("搜索里诶啊哦", res);
					if (res.code == 0) {
						this.listArr = res.result;
					}
				});
			},
			headBtn(e) {
				this.idx = e;
				if (e == 1) {
					this.$router.push("/");
				} else if (e == 2) {
					this.$router.push("/investmentAdvantages");
				} else if (e == 3) {
					this.$router.push("/investmentIndustry");
				} else if (e == 4) {
					this.$router.push("/policySupport");
				} else if (e == 5) {
					this.$router.push("/elementGuarantee");
				} else if (e == 6) {
					this.$router.push("/toInvest");
				} else if (e == 9) {
					// this.$router.push('/searchEngines')
					this.isShow = true;
				} else if (e == 10) {
					this.$router.push("/resources");
				} else if (e == 7) {
					// window.location.href='http://jhj.s0916.cn/login.aspx'
					window.open('http://jhj.s0916.cn/login.aspx')
				} else if (e == 8) {
					this.$message({
						message: "正在开发中",
						type: "success",
					});
				}
			},
		},
	};
</script>

<style scoped>
	.popup_ximg {
		position: absolute;
		width: 48px;
		height: 48px;
		top: 20px;
		right: 140px;
		cursor: pointer;
	}

	.popup_text {
		height: 72%;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.popup_text>p {
		color: #ffffff;
		line-height: 1.8;
	}

	.popup_title {
		font-size: 30px;
		font-weight: bolder;
		text-align: center;
		color: #ffffff;
		padding: 24px 0;
	}

	.popup_time {
		font-size: 18px;
		color: #376092;
		padding-bottom: 20px;
		border-bottom: 1px solid #376092;
		margin-bottom: 10px;
	}

	.popup_content {
		position: absolute;
		top: 3%;
		left: 50%;
		transform: translateX(-50%);
		width: 75%;
		height: 80%;
		background-color: #011742;
		padding: 30px 40px 30px;
		box-sizing: border-box;
	}

	.support_popup {
		position: fixed;
		width: 1536px;
		height: 718px;
		background-color: rgba(1, 30, 90, 0.8);
		top: 0;
		left: 0;
		z-index: 80;
	}

	.web_btn>p {
		margin-right: 26px;
		color: #ffffff;
		font-size: 18px;
		padding: 15px 0;
		box-sizing: border-box;
		cursor: pointer;
	}

	.web_btn>p.active {
		border-bottom: 5px solid #0051ff;
	}

	.list_box {
		padding: 15px;
		background-color: #031644;
		margin-bottom: 15px;
		border-radius: 5px;
	}

	.list_box>p {
		font-size: 16px;
		color: #ffffff;
	}

	.pHover {
		cursor: pointer;
	}

	.pHover:hover {
		color: #0051ff;
		font-weight: bolder;
	}

	.sou_text {
		font-size: 18px;
		color: #ffffff;
		padding: 20px 0 15px 0;
	}

	.popup_list {
		height: 620px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.delete_img,
	.sou_img {
		width: 22px;
		height: 22px;
		margin-right: 5px;
	}

	.popup_top {
		top: 30%;
	}

	.sou_box {
		width: 100%;
		height: 45px;
		background-color: #ffffff;
		border-radius: 5px;
	}

	.popup_top_bian {
		top: 10%;
		animation-name: zhang;
		animation-duration: 1s;
		animation-timing-function: ease-in;
	}

	@keyframes zhang {
		0% {
			top: 30%;
		}

		100% {
			top: 10%;
		}
	}

	.public_content {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		width: 50%;
	}

	.sou_box>input {
		padding-left: 20px;
		box-sizing: border-box;
		width: 70%;
		height: 45px;
		border-radius: 5px;
		border: 0;
		font-size: 18px;
		outline: none;
	}

	.public_popup {
		position: fixed;
		width: 1536px;
		height: 718px;
		background-color: rgba(1, 30, 90, 0.9);
		z-index: 55;
		top: 0;
		left: 0;
	}

	.login_head {
		/* position: fixed;
  top: 0; */
		width: 1536px;
		height: 70px;
		background: -webkit-linear-gradient(0deg,
				rgba(1, 23, 66, 0.65),
				rgba(0, 81, 255, 0.65));
		background: linear-gradient(0deg,
				rgba(1, 23, 66, 0.65),
				rgba(0, 81, 255, 0.65));
	}

	.web_box {
		padding-left: 30px;
	}

	.web_title {
		width: 380px;
		line-height: 1;
		font-size: 28px;
		font-family: SourceHanSansCN;
		font-weight: bold;
		color: #9ed1ff;
		letter-spacing: 4.2px;
		text-align: center;
	}

	.web_text {
		width: 380px;
		line-height: 1;
		font-size: 12px;
		font-family: SourceHanSansCN;
		font-weight: 400;
		color: #9edaff;
		margin-top: 8px;
		letter-spacing: 1px;
	}

	.head_btn>p,
	.head_btn>a {
		font-size: 24px;
		line-height: 1;
		font-family: SourceHanSansCN;
		font-weight: 500;
		color: #ffffff;
		margin-right: 22px;
		padding-bottom: 18px;
		box-sizing: border-box;
		cursor: pointer;
	}

	/* .head_btn > p.active {
  border-bottom: 4px solid #0051ff;
} */
</style>